import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Select from "react-select";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AudioEditorController, { Props, configJSON } from "./AudioEditorController.web";
import { AudioPicker } from "./AudioPicker.web";
import { AudioDataType } from "./AudioEditorController";
import { AudioTrimmer } from "../../../components/src/AudioTrimmer.web";

export default class AudioEditor extends AudioEditorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  fromDeviceView() {
    return (
      <>
        {this.state.audioListData.length == 0 &&
        !this.state.audioFile &&
        !this.state.isTrimmerVisible ? (
          this.pickAudioScreen()
        ) : (
          <div
            data-test-id={"resetButtonView"}
            onClick={this.resetMusic}
            style={{
              ...webStyle.stopButton,
              pointerEvents: "all",
            }}
          >
            <Typography style={{ color: "white" }}>{configJSON.resetLabel}</Typography>
          </div>
        )}
        {this.state.audioFile &&
        !this.state.isTrimmerVisible &&
        (!this.state.trimmedAudio || this.state.trimmedAudio === "--")
          ? this.selectedAudio()
          : null}
        {this.state.isTrimmerVisible ? this.audioTrimmerView() : null}
        {this.state.trimmedAudio && this.state.trimmedAudio !== "--"
          ? this.trimmedAudioView()
          : null}
      </>
    );
  }

  trimmedAudioView() {
    return (
      <Box>
        <Typography>{configJSON.trimmedAudioLabel}</Typography>
        {!this.state.playing ? (
          <Box style={webStyle.playButton} data-test-id='playButton' onClick={this.playMusic}>
            <Typography style={webStyle.whiteTxt}>{configJSON.playLabel}</Typography>
          </Box>
        ) : (
          <Box style={webStyle.pauseButton} data-test-id='pauseButton' onClick={this.pauseMusic}>
            <Typography style={webStyle.whiteTxt}>{configJSON.pauseLabel}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  audioTrimmerView() {
    const { totalDuration, trimmerRightHandlePosition, trimmerLeftHandlePosition } = this.state;

    return (
      <Box>
        {!this.state.playing ? (
          <Box
            data-test-id={configJSON.playButtonTestId}
            style={webStyle.playButton}
            onClick={this.playMusic}
          >
            <Typography style={webStyle.whiteTxt}>{configJSON.playLabel}</Typography>
          </Box>
        ) : (
          <Box
            data-test-id={configJSON.pauseButtonTestId}
            style={webStyle.pauseButton}
            onClick={this.pauseMusic}
          >
            <Typography style={webStyle.whiteTxt}>{configJSON.pauseLabel}</Typography>
          </Box>
        )}
        <Box
          data-test-id={configJSON.stopButtonTestId}
          style={webStyle.stopButton}
          onClick={this.stopCurrentMusic}
        >
          <Typography style={webStyle.whiteTxt}>{configJSON.stopLabel}</Typography>
        </Box>
        <Box style={{ marginBottom: 10 }}>
          <Typography style={{ marginBottom: 5 }}>
            {trimmerLeftHandlePosition} {configJSON.dashLabel} {trimmerRightHandlePosition}
          </Typography>
          <Typography style={{ marginBottom: 5 }}>
            {configJSON.TotalDurationLabel} {totalDuration} {configJSON.secondsLabel}
          </Typography>
        </Box>
        <AudioTrimmer
          setDuration={this.setCurrentDuration}
          fileName={
            this.state.fileName !== "" ? this.state.fileName : this.state.audioFile?.name ?? ""
          }
          rightHandle={this.state.trimmerRightHandlePosition}
          leftHandle={this.state.trimmerLeftHandlePosition}
          postAudio={this.postTrimmedAudioData}
          handleStartEndTime={(start: number, endTime: number) => {
            this.handleRangeChange([start, endTime]);
          }}
          audioFile={this.state.currentAudio}
          file={this.state.audioFile}
        />
      </Box>
    );
  }

  selectedAudio() {
    const { audioFile } = this.state;
    return (
      <Box>
        <Typography>
          {configJSON.nameLabel}
          {audioFile?.name}
        </Typography>
        <Typography>
          {configJSON.sizeLabel}
          {audioFile?.size}
        </Typography>
        <Typography>
          {configJSON.typeLabel}
          {audioFile?.type}
        </Typography>
        <Box
          data-test-id={"startTrimButton"}
          style={webStyle.pickAudioButton}
          onClick={this.startTrim}
        >
          <Typography style={webStyle.pickAudioText}>{configJSON.startTrimButtonLabel}</Typography>
        </Box>
      </Box>
    );
  }

  fromApiView = () => {
    return (
      <>
        {this.state.audioListData && !this.state.isTrimmerVisible
          ? (!this.state.trimmedAudio || this.state.trimmedAudio === "--") && (
              <Select
                data-test-id={configJSON.drowdownTestId}
                {...this.fromApiSelectProps}
                options={this.state.audioListData.slice(0).map((audioItem: AudioDataType) => {
                  const newObject = {
                    label: audioItem.attributes.title,
                    value: audioItem,
                  };
                  return { ...newObject };
                })}
              />
            )
          : null}
        {this.state.isTrimmerVisible ? this.audioTrimmerView() : null}
        {this.state.trimmedAudio && this.state.trimmedAudio !== "--"
          ? this.trimmedAudioView()
          : null}
        <Box onClick={this.resetMusic} style={{ ...webStyle.stopButton, pointerEvents: "all" }}>
          <Typography style={webStyle.whiteTxt}>{configJSON.resetLabel}</Typography>
        </Box>
      </>
    );
  };

  pickAudioScreen() {
    return (
      <Box>
        <Typography>{configJSON.pickAudioFromDeviceLabel}</Typography>
        <Typography>{configJSON.supportMp3Label}</Typography>
        <Box
          data-test-id={"openPickerButton"}
          style={webStyle.pickAudioButton}
          onClick={() => {
            this.pickAudioFile(true, undefined, 0);
          }}
        >
          <Typography style={webStyle.pickAudioText}>{configJSON.pickAudioLabel}</Typography>
        </Box>
        <Typography>{configJSON.orLabel}</Typography>
        <Box
          data-test-id={"getAudioFromApiButton"}
          style={webStyle.pickAudioButton}
          onClick={this.getAllAudioData}
        >
          <Typography style={webStyle.pickAudioText}>{configJSON.pickAudioApiLabel}</Typography>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box style={{ height: "100%", width: "100%", alignItems: "center" }}>
            {this.state.screenName === "FromDeviceView" ? this.fromDeviceView() : null}
            {this.state.screenName === "FromApiView" ? this.fromApiView() : null}
            <Modal
              open={this.state.isTrimming}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box style={webStyle.trimContainer}>
                <Box style={webStyle.modalViewChild}>
                  <Typography>{configJSON.isTrimmingLabel}</Typography>
                </Box>
              </Box>
            </Modal>

            {this.state.isAudioPickerOpen && (
              <AudioPicker openPicker={this.state.isAudioPickerOpen} {...this.audioPickerProps} />
            )}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  trimContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalViewChild: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 260,
    width: "75%",
    borderRadius: 10,
    marginHorizontal: 50,
    marginVertical: 250,
  },
  pickAudioButton: {
    backgroundColor: "teal",
    width: "50%",
    padding: 8,
  },
  pickAudioText: {
    color: "white",
  },
  pauseButton: {
    backgroundColor: "grey",
    width: "50%",
    padding: 4,
    marginBottom: 5,
  },
  playButton: {
    backgroundColor: "green",
    width: "50%",
    padding: 4,
    marginTop: 5,
    marginBottom: 5,
  },
  whiteTxt: {
    color: "#ffffff",
  },
  stopButton: {
    background: "red",
    padding: 4,
    width: "50%",
    marginBottom: 5,
  },
};
// Customizable Area End
