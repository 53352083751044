import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
export interface ISchedule {
  id?: string;
  event_name: string;
  start_time: string;
  start_hour: string;
  duration: string;
  description: string;
}

export interface IScheduleResponse {
  id?: string;
  attributes: {
    description: string;
    duration: string;
    event_name: string;
    start_date: string;
    start_time: string;
  };
}

export interface EventListItem {
  id: string;
  type: string;
  attributes: {
    event_name: string;
    description: string;
    duration: string;
    start_date: string;
    start_time: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentSchedule: ISchedule;
  schedules: ISchedule[];
  isEdit: boolean;

  isVisible: boolean;
  isFromEdit: boolean;
  modalVisible: boolean;
  textLength: number;
  textLenght2: number;
  maxLength: number;
  selectedStartDate: string;
  colorId: number;
  amPmColorId: number;
  liveEventTopic: string;
  liveEventDesc: string;
  startDate: string;
  token: string;
  userDetails: number;
  eventList: EventListItem[];
  warnTopic: string;
  warnTopicDes: string;
  warnDate: string;
  warnTime: string;
  selectTimeHour: string;
  selectTimeMinutes: string;
  selectedDurationHour: string;
  selectedDurationMinutes: string;
  selectDurationHour: string;
  selectDurationMinutes: string;
  warnDurationTime: string;
  timePicker: boolean;
  durationTimePicker: boolean;
  time: string;
  durationTime: string;
  durtionData: {
    hh: string;
    mm: string;
  };
  currentDeleteData: EventListItem | undefined;
  currentEventEdit: EventListItem | undefined;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LiveFeedSchedulingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSchedulesCallId: string = "";
  createSchedulesCallId: string = "";
  token: string = "";
  scheduleLiveEventApiCallId: string = "";
  getEventListApiCallId: string = "";
  deleteEventListApiCallId: string = "";
  editEventListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.token = window.localStorage.getItem("testToken") || "";

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentSchedule: {
        event_name: "",
        start_time: "00:00",
        start_hour: "00:00 AM",
        duration: "00:00",
        description: "00:00",
      },
      schedules: [],
      modalVisible: false,
      isEdit: false,

      isVisible: false,
      isFromEdit: false,
      textLength: 0,
      textLenght2: 0,
      maxLength: 32,
      selectedStartDate: "",
      colorId: 0,
      amPmColorId: 1,
      liveEventTopic: "",
      liveEventDesc: "",
      startDate: "",
      token: "",
      userDetails: 251,
      eventList: [],
      warnTopic: "",
      warnTopicDes: "",
      warnDate: "",
      warnTime: "",
      warnDurationTime: "",
      selectTimeHour: "",
      selectTimeMinutes: "",
      selectedDurationHour: "",
      selectedDurationMinutes: "",
      selectDurationHour: "",
      selectDurationMinutes: "",
      timePicker: false,
      durationTimePicker: false,
      time: "",
      durationTime: "",
      durtionData: {
        hh: "00",
        mm: "00",
      },
      currentDeleteData: undefined,
      currentEventEdit: undefined,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (this.getSchedulesCallId === apiRequestCallId) {
      if (responseJson.data && responseJson.data.length > 0) {
        const newSchedules: ISchedule[] = responseJson.data.map((schedule: IScheduleResponse) => ({
          id: schedule.id,
          event_name: schedule.attributes.event_name,
          start_time: schedule.attributes.start_date,
          start_hour: schedule.attributes.start_time,
          duration: schedule.attributes.duration,
          description: schedule.attributes.description,
        }));
        this.setState({
          schedules: newSchedules,
          modalVisible: false,
        });
      }
    } else if (this.createSchedulesCallId === apiRequestCallId) {
      this.getSchedules();
    } else {
      this.scheduleLiveEvent(apiRequestCallId, message);

      this.getEventListResponse(apiRequestCallId, message);
      this.deleteLiveEvent(apiRequestCallId, message);
      this.editLiveEvent(apiRequestCallId, message);
    }
    // Customizable Area End
  }
  // Customizable Area Start

  editLiveEvent = (requestId: string, msgData: Message) => {
    const resData = msgData.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const errorResponseData = msgData.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (requestId === this.editEventListApiCallId) {
      this.setState({
        eventList: [...this.state.eventList, resData && resData.data],
      });

      this.parseApiCatchErrorResponse(errorResponseData);
    }
  };

  deleteLiveEvent = (reqId: string, messageData: Message) => {
    const responseData = messageData.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const errorResponse = messageData.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (reqId === this.deleteEventListApiCallId) {
      if (responseData != null) {
        if (!responseData.errors) {
          this.setState({
            eventList: this.state.eventList.filter(
              (eventItem: EventListItem) =>
                eventItem.id != (this.state.currentDeleteData && this.state.currentDeleteData.id)
            ),
          });
        } else {
          this.showAlert("error", "Something went wrong");
        }
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }
  };

  getEventListResponse = (apiReqId: string, message: Message) => {
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const errorRes = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiReqId === this.getEventListApiCallId) {
      if (response != null) {
        if (!response.errors) {
          this.setState({ eventList: response.data });
        } else {
          this.showAlert("error", "Something went wrong");
        }
      }
      this.parseApiCatchErrorResponse(errorRes);
    }
  };

  scheduleLiveEvent = (apiRequestCallId: string, message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId === this.scheduleLiveEventApiCallId) {
      if (responseJson) {
        if (this.state.currentEventEdit == undefined) {
          this.setState({
            eventList: [...this.state.eventList, responseJson.data],
          });
        } else {
          
          this.setState({
            eventList: this.state.eventList.map((data: EventListItem) => {
              if (data.id == responseJson.data.id) {
                return {
                  ...responseJson.data,
                };
              } else {
                return {
                  ...data,
                };
              }
            }),
          });
        }
        this.setState({
          currentEventEdit: undefined,
        });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  setEventName = (text: string) => {
    this.setState({ currentSchedule: { ...this.state.currentSchedule, event_name: text } });
  };

  setStartTime = (date: Date) => {
    this.setState({
      currentSchedule: { ...this.state.currentSchedule, start_time: date.toDateString() },
    });
  };

  setStartHour = (hour: string) => {
    hour = hour.substring(0, 2);
    this.setState({
      currentSchedule: {
        ...this.state.currentSchedule,
        start_hour: hour + ":" + this.state.currentSchedule.start_hour.split(":")[1],
      },
    });
  };
  setStartMinute = (minute: string) => {
    minute = minute.substring(0, 2);
    this.setState({
      currentSchedule: {
        ...this.state.currentSchedule,
        start_hour:
          this.state.currentSchedule.start_hour.split(":")[0] +
          ":" +
          minute +
          " " +
          this.state.currentSchedule.start_hour.split(" ")[1],
      },
    });
  };

  setStartAmPm = (value: string) => {
    this.setState({
      currentSchedule: {
        ...this.state.currentSchedule,
        start_hour: this.state.currentSchedule.start_hour.split(" ")[0] + " " + value,
      },
    });
  };

  setDurationHour = (hour: string) => {
    hour = hour.substring(0, 2);
    this.setState({
      currentSchedule: {
        ...this.state.currentSchedule,
        duration: hour + ":" + this.state.currentSchedule.duration.split(":")[1],
      },
    });
  };
  setDurationMinute = (minute: string) => {
    minute = minute.substring(0, 2);
    this.setState({
      currentSchedule: {
        ...this.state.currentSchedule,
        duration: this.state.currentSchedule.duration.split(":")[0] + ":" + minute,
      },
    });
  };

  setDescription = (text: string) => {
    this.setState({
      currentSchedule: {
        ...this.state.currentSchedule,
        description: text,
      },
    });
  };

  getSchedules = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSchedulesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listEventAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openModal = (schedule?: ISchedule) => {
    if (schedule) this.setState({ modalVisible: true, currentSchedule: schedule, isEdit: true });
    else {
      this.clearCurrentSchedule();
      this.setState({ modalVisible: true, isEdit: false });
    }
  };

  onModalClose = () => this.setState({ modalVisible: false });

  deleteSchedule = (schedule?: ISchedule) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createSchedulesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventAPiEndPoint}?id=${schedule && schedule.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editCreateSchedule = (isCreate: boolean) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createSchedulesCallId = requestMessage.messageId;
    if (isCreate)
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.eventAPiEndPoint}`
      );
    else
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.eventAPiEndPoint}?id=${this.state.currentSchedule.id}`
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let formData = new FormData();
    formData.append("event_name", this.state.currentSchedule.event_name);
    formData.append("duration", this.state.currentSchedule.duration);
    formData.append("description", this.state.currentSchedule.description);
    formData.append("start_hour", this.state.currentSchedule.start_hour);
    formData.append("start_time", this.state.currentSchedule.start_time);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    if (isCreate) requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    else requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  clearCurrentSchedule = () => {
    this.setState({
      currentSchedule: {
        event_name: "",
        start_time: "00:00",
        start_hour: "00:00",
        duration: "00:00",
        description: "",
      },
    });
  };

  async componentDidMount() {
    this.getSchedules();
    this.clearCurrentSchedule();

    this.getToken();
    const userId = await getStorageData("authTokenId");
    this.setState({ userDetails: userId });
  }

  doButtonPressed() {
    let msgData = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgData.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.txtInputValue);
    this.send(msgData);
  }

  // web events
  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  onPressCloseModel = () => {
    this.setState({ isVisible: false });
    this.setState({
      currentEventEdit: undefined,
      textLength: 0,
      textLenght2: 0,
      maxLength: 32,
      selectedStartDate: "",
      colorId: 0,
      amPmColorId: 1,
      liveEventTopic: "",
      liveEventDesc: "",
      startDate: "",
      selectTimeHour: "",
      selectTimeMinutes: "",
      selectedDurationHour: "",
      selectedDurationMinutes: "",
      selectDurationHour: "",
      selectDurationMinutes: "",
      timePicker: false,
      durationTimePicker: false,
      time: "",
      durationTime: "",
      durtionData: {
        hh: "00",
        mm: "00",
      },
    });
  };

  onPressOpenModel = () => {
    this.setState({
      isVisible: true,
      startDate: "",
      warnDate: "",
      warnDurationTime: "",
      warnTopic: "",
      warnTopicDes: "",
      warnTime: "",
    });
  };

  onChangeTextTopicTitle(text: string) {
    this.setState({
      textLength: text.toString().length,
      liveEventTopic: text,
      warnTopic: "",
    });
  }
  onChangeTextTopicDescTitle(text2: string) {
    this.setState({
      textLenght2: text2.toString().length,
      liveEventDesc: text2,
      warnTopicDes: "",
    });
  }
  onDateChange = (date: string) => {
    this.setState({
      selectedStartDate: date,
      modalVisible: false,
      warnDate: "",
      startDate: date,
    });
  };

  onPressLive = () => {};

  convertTime = (timeStr: string) => {
    const [time, modifier] = timeStr && timeStr.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = `${parseInt(hours, 10) + 12}`;
    }
    return `${hours}:${minutes}`;
  };

  onTimeSelected(value: string | undefined) {
    this.setState({ timePicker: false, time: value || "", warnTime: "" });
  }

  onDuration(value2: string | undefined) {
    this.setState({ durationTimePicker: false, durationTime: value2 || "", warnDurationTime: "" });
  }
  async getToken() {
    const token = await getStorageData("authToken", false);
    this.getEventList(token);
    this.setState({
      token: token,
    });
  }
  onPressScheduleLiveEvent = () => {
    if (this.state.liveEventTopic === "") {
      this.setState({ warnTopic: "please select topic" });
    } else if (this.state.liveEventDesc === "") {
      this.setState({ warnTopicDes: "please select description" });
    } else if (this.state.selectedStartDate === "") {
      this.setState({ warnDate: "please select date" });
    } else if (this.state.time === "") {
      this.setState({ warnTime: "please select time" });
    } else if (this.state.durationTime === "") {
      this.setState({ warnDurationTime: "please select duration" });
    } else {
      let eventData = {
        event_name: this.state.liveEventTopic,
        description: this.state.liveEventDesc,
        start_date: this.state.selectedStartDate,
        start_time: this.state.time,
        duration: this.state.durationTime,
      };

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.scheduleLiveEventApiCallId = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json",
        token: this.state.token,
      };

      if (this.state.currentEventEdit == undefined) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.scheduleLiveEventEndPoint}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPostMethod
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.scheduleLiveEventEndPoint}?id=${
            this.state.currentEventEdit && this.state.currentEventEdit.id
          }`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.examplePutAPiMethod
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(eventData)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({ isVisible: false });
      this.setState({
        textLength: 0,
        textLenght2: 0,
        maxLength: 32,
        selectedStartDate: "",
        colorId: 0,
        amPmColorId: 0,
        liveEventTopic: "",
        liveEventDesc: "",
        startDate: "",
        selectTimeHour: "",
        selectTimeMinutes: "",
        selectedDurationHour: "",
        selectedDurationMinutes: "",
        selectDurationHour: "",
        selectDurationMinutes: "",
        timePicker: false,
        durationTimePicker: false,
        time: "",
        durationTime: "",
        durtionData: {
          hh: "00",
          mm: "00",
        },
      });
    }
  };
  // Customizable Area End
  // Customizable Area Start
  deleteEvent = (data: EventListItem) => {
    this.setState({
      currentDeleteData: data,
    });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteEventListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventlistDeleteEndpoint}?id=${data && data.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeStateOnEdit = (item: EventListItem) => {
    this.setState({
      isVisible: true,
      isFromEdit: true,
      currentEventEdit: item,
      liveEventTopic: item.attributes && item.attributes.event_name,
      liveEventDesc: item.attributes && item.attributes.description,
      startDate: item.attributes && item.attributes.start_date,
      selectedStartDate: item.attributes && item.attributes.start_date,
      time: item.attributes && item.attributes.start_time,
      durationTime: (item.attributes && item.attributes.duration) || "",
    });
  };

  getStartTimerText = (item: EventListItem) => {
    let finalData = item.attributes.start_date.split("-").reverse().join("-");
    let stringDate = new Date(`${finalData}T${this.convertTime(item.attributes.start_time)}`);
    let dateObj = moment(stringDate, "YYYY-MM-DDTHH:mm").toDate().getTime();
    let finalTimeInMile = dateObj - new Date().getTime();
    return new Date(finalTimeInMile).toISOString().slice(11, 19);
  };

  getEventList = (token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEventListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventlistEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
