import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ValueType } from "react-select";
// Customizable Area Start
export interface AudioDataType {
  attributes: {
    audio: string;
    id: number;
    created_at: string;
    title: string | null;
  };
  id: string;
  type: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  screenName: string;
  isTrimming: boolean;
  audioListData: AudioDataType[];
  audioFile: File | undefined;
  isTrimmerVisible: boolean;
  isDownloading: boolean;
  isLoading: boolean;
  trimmedAudio: string;
  pickAudioScreen: boolean;
  selectedAudio: AudioDataType | null;
  playing: boolean;
  music: HTMLAudioElement | undefined;
  isAudioPickerOpen: boolean;
  currentAudio: string;
  totalDuration: number;
  maxTrimDuration: number;
  trimmerRightHandlePosition: number;
  trimmerLeftHandlePosition: number;
  minTrimDuration: number;
  tempLpos: number;
  tempRpos: number;
  audioPickerEvent: React.ChangeEvent<HTMLInputElement> | undefined;
  isTrimmingStarted: boolean;
  token: string;
  fileName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AudioEditorController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllAudioDataCallId: string = "";
  audio: HTMLAudioElement | null = null;
  timer: ReturnType<typeof setTimeout> | null = null;
  postTrimmedAudioDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      screenName: "FromDeviceView",
      isTrimming: false,
      audioListData: [],
      audioFile: undefined,
      isTrimmerVisible: false,
      isDownloading: false,
      isLoading: false,
      trimmedAudio: "--",
      pickAudioScreen: false,
      selectedAudio: null,
      playing: false,
      music: undefined,
      isAudioPickerOpen: false,
      currentAudio: "",
      totalDuration: 0,
      maxTrimDuration: 1000,
      trimmerRightHandlePosition: 0,
      trimmerLeftHandlePosition: 0,
      minTrimDuration: 0,
      tempLpos: 0,
      tempRpos: 0,
      audioPickerEvent: undefined,
      isTrimmingStarted: false,
      fileName: "",
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestId === this.getAllAudioDataCallId) {
        if (!responseData.errors) {
          const { data } = responseData;
          const mappedArray = await data.slice(0).map(
            (response: {
              attributes: {
                audios: { data: AudioDataType[] };
              };
            }) => response.attributes.audios.data
          );
          const flatlistData = [].concat.apply([], mappedArray);
          this.setState({ audioListData: [...flatlistData], screenName: "FromApiView" });
        }
      }

      if (apiRequestId === this.postTrimmedAudioDataCallId) {
        if (responseData.data?.attributes?.trim_audio) {
          this.showAlert("Upload finished", "Successfully saved in server");
          this.resetMusic();
        } else {
          this.showAlert("Error", "Something went wrong");
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  audioPickerProps = {
    onSelect: (file: File | undefined, duration: number) => {
      this.pickAudioFile(false, file, duration);
    },
  };

  fromApiSelectProps = {
    onChange: (
      event: ValueType<
        {
          label: string | null;
          value: AudioDataType;
        },
        false
      >
    ) => {
      this.setDropDownData(
        event?.value?.attributes?.title ?? "",
        event?.value ?? null,
        event?.value?.attributes?.audio ?? ""
      );
    },
  };

  pickAudioFile = async (isPicking: boolean, file: File | undefined, duration: number) => {
    if (isPicking) {
      this.setState({ isAudioPickerOpen: true });
    } else {
      this.setState({ isAudioPickerOpen: false });
      if (file != undefined) {
        let newFile = URL.createObjectURL(file);
        this.setState(
          {
            trimmerRightHandlePosition: duration,
            currentAudio: newFile,
            audioFile: file,
            maxTrimDuration: duration,
            totalDuration: duration,
            isTrimmerVisible: true,
          },
          () => {
            this.startTrim();
          }
        );
      }
    }
  };

  setCurrentDuration = (time: number) => {
    this.setState({ totalDuration: time });
  };

  postTrimmedAudioData = async (blob: Blob) => {
    let newToken = localStorage.getItem("token");
    const header = {
      token: newToken,
    };

    let formdata = new FormData();
    formdata.append(
      "[data][trim_audio]",
      new File([blob], "filename.mp3", { type: "audio/mpeg" }),
      "[PROXY]"
    );
    formdata.append(
      "[data][start_time]",
      `${this.formatCurrentTime(this.state.trimmerLeftHandlePosition)}`
    );
    formdata.append(
      "[data][end_time]",
      `${this.formatCurrentTime(this.state.trimmerRightHandlePosition)}`
    );
    formdata.append("[data][audio_id]", "48");

    const postDataMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postTrimmedAudioDataCallId = postDataMessage.messageId;

    postDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postAudioEndpoint
    );
    postDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    postDataMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    postDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(postDataMessage.id, postDataMessage);
  };

  formatCurrentTime(seconds: number) {
    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds % 3600) / 60);
    const second = Math.round(seconds % 60);
    return [
      hour > 9 ? hour : "0" + hour,
      minute > 9 ? minute : "0" + minute,
      second > 9 ? second : "0" + second,
    ]
      .filter(Boolean)
      .join(":");
  }

  getAllAudioData = async () => {
    let newToken = localStorage.getItem("token");
    const header = {
      token: newToken,
    };
    const audioMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllAudioDataCallId = audioMessage.messageId;

    audioMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAudioDataEndpoint
    );

    audioMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    audioMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(audioMessage.id, audioMessage);
  };

  startTrim = async () => {
    this.setState({ isTrimmerVisible: true });
    await this.addNewMusic(this.state.currentAudio);
  };

  resetMusic = () => {
    this.setState({
      pickAudioScreen: true,
      trimmedAudio: "",
      audioListData: [],
      trimmerRightHandlePosition: 0,
      totalDuration: 0,
      selectedAudio: null,
      screenName: "FromDeviceView",
      playing: false,
      currentAudio: "",
      fileName: "",
      trimmerLeftHandlePosition: 0,
      audioFile: undefined,
      isTrimmerVisible: false,
    });
    this.audio!.pause();
    this.audio!.currentTime = 0;
  };

  setPlaying = (isPlaying: boolean) => {
    this.setState({ playing: isPlaying });
  };

  playMusic = () => {
    this.setPlaying(true);
    this.audio!.play();
    this.audio!.addEventListener("ended", () => {
      this.setState({ playing: false });
    });
    this.timer = this.callTimer();

    if (this.timer) clearTimeout(this.timer);
  };

  setFileName = (name: string) => {
    this.setState({ fileName: name });
  };

  setDropDownData = (title: string, audio: AudioDataType | null, currentAudio: string) => {
    this.setFileName(title);
    this.setState({ selectedAudio: audio, currentAudio: currentAudio }, () => {
      this.startTrim();
    });
  };

  callTimer = () =>
    setTimeout(() => {
      this.audio!.pause();
      this.audio!.currentTime = this.state.trimmerLeftHandlePosition;
      this.setState({ playing: false });
    }, this.state.trimmerRightHandlePosition - this.state.trimmerLeftHandlePosition);

  pauseMusic = () => {
    this.audio!.pause();
    this.setState({ playing: false });
  };

  addNewMusic = async (currentAudio: string) => {
    this.audio = new Audio(currentAudio);
    this.setState({ music: this.audio });
  };

  stopCurrentMusic = () => {
    this.setState({ playing: false });
    this.audio!.pause();
  };

  handleRangeChange = ([rangeStart, rangeEnd]: number[]) => {
    this.audio!.currentTime = rangeStart;
    this.audio!.pause();
    this.setState({
      playing: false,
      isTrimmingStarted: true,
      trimmerLeftHandlePosition: rangeStart,
      trimmerRightHandlePosition: rangeEnd,
    });
  };

  setTrimmedAudio = (audio: string) => {
    this.setState({ trimmedAudio: audio, audioFile: undefined, isTrimmerVisible: true });
  };

  // Customizable Area End
}
