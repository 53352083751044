import React, { useEffect, useState, useRef } from "react";

export const AudioPicker = (props: {
  openPicker: boolean;
  onSelect: (file: File | undefined, duration: number) => void;
}) => {
  const pickerRef = useRef<HTMLInputElement>(document.createElement("input"));

  //create new HTMLInputElement

  const audioRef = useRef<HTMLAudioElement>({
    duration: 0,
  } as HTMLAudioElement);

  const [file, setFile] = useState<File>();

  const handleLoad = async () => {
    props.onSelect(file, audioRef.current.duration || 0);
  };

  useEffect(() => {
    if (props.openPicker) pickerRef.current.click();
  }, [props.openPicker]);

  return (
    <div>
      <input
        ref={pickerRef}
        style={{ display: "none" }}
        type='file'
        accept='audio/*'
        name='image-upload'
        id='input'
        data-test-id='file-input-handler'
        onChange={async (event) => {
          let mewFile: File | null;
          if (
            event.target.files !== null &&
            event.target.files !== undefined &&
            event.target.files.length > 0 &&
            event.target.files.length < 2
          ) {
            mewFile = event.target.files[0];
          } else {
            mewFile = null;
          }
          if (mewFile !== null && mewFile !== undefined) {
            setFile(mewFile);
          }
        }}
      />
      <audio
        data-test-id={"audioPlayer"}
        style={{ display: "none" }}
        ref={audioRef}
        src={file ? URL.createObjectURL(file) : ""}
        onLoadedMetadata={handleLoad}
      />
    </div>
  );
};
