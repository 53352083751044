import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import {
    Box,
    Typography,
} from "@material-ui/core";

//@ts-ignore
import lamejs from 'lamejs';


export const AudioTrimmer = (props: any) => {

    const [waveSurfer, setWaveSurfer] = useState<WaveSurfer>();
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [isReady, setIsReady] = useState(false);

    const downloadMp3 = (start: any, end: any, toDownload: boolean) => {
        //@ts-ignore
        var bufferStart = Math.floor(start * waveSurfer.backend.buffer.sampleRate);
        //@ts-ignore
        var bufferEnd = Math.floor(end * waveSurfer.backend.buffer.sampleRate);
        //@ts-ignore
        analyzeAudioBuffer(toDownload, waveSurfer.backend.buffer, bufferStart, bufferEnd);
    }

    const analyzeAudioBuffer = (toDownload: boolean, aBuffer: any, start: any, end: any) => {
        let numOfChan = aBuffer.numberOfChannels,
            btwLength = aBuffer.length * numOfChan * 2 + 44,
            btwArrBuff = new ArrayBuffer(btwLength),
            btwView = new DataView(btwArrBuff),
            btwChnls = [],
            btwIndex,
            btwSample,
            btwOffset = 0,
            btwPos = 0;

        const setUint16 = (data: any) => {
            btwView.setUint16(btwPos, data, true);
            btwPos += 2;
        }

        const setUint32 = (data: any) => {
            btwView.setUint32(btwPos, data, true);
            btwPos += 4;
        }

        setUint32(0x46464952); // "RIFF"
        setUint32(btwLength - 8); // file length - 8
        setUint32(0x45564157); // "WAVE"
        setUint32(0x20746d66); // "fmt " chunk
        setUint32(16); // length = 16
        setUint16(1); // PCM (uncompressed)
        setUint16(numOfChan);
        setUint32(aBuffer.sampleRate);
        setUint32(aBuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
        setUint16(numOfChan * 2); // block-align
        setUint16(16); // 16-bit
        setUint32(0x61746164); // "data" - chunk
        setUint32(btwLength - btwPos - 4); // chunk length

        for (btwIndex = 0; btwIndex < aBuffer.numberOfChannels; btwIndex++)
            btwChnls.push(aBuffer.getChannelData(btwIndex));

        while (btwPos < btwLength) {
            for (btwIndex = 0; btwIndex < numOfChan; btwIndex++) {
                // interleave btwChnls
                btwSample = Math.max(-1, Math.min(1, btwChnls[btwIndex][btwOffset])); // clamp
                btwSample = (0.5 + btwSample < 0 ? btwSample * 32768 : btwSample * 32767) | 0; // scale to 16-bit signed int
                btwView.setInt16(btwPos, btwSample, true); // write 16-bit sample
                btwPos += 2;
            }
            btwOffset++; // next source sample
        }

        let wavHdr = lamejs.WavHeader.readHeader(new DataView(btwArrBuff));

        //Stereo
        let data = new Int16Array(btwArrBuff, wavHdr.dataOffset, wavHdr.dataLen / 2);
        let leftData = [];
        let rightData = [];
        for (let i = 0; i < data.length; i += 2) {
            leftData.push(data[i]);
            rightData.push(data[i + 1]);
        }
        var left = new Int16Array(leftData);
        var right = new Int16Array(rightData);

        //STEREO
        if (wavHdr.channels === 2)
            bufferToMp3(toDownload, start, end, wavHdr.channels, wavHdr.sampleRate, left, right);
        //MONO
        else if (wavHdr.channels === 1)
            bufferToMp3(toDownload, start, end, wavHdr.channels, wavHdr.sampleRate, data, null);
    }

    const bufferToMp3 = (toDownload: boolean, start: any, end: any, channels: any, sampleRate: any, left: any, right?: any) => {
        var buffer = [];
        var mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);

        var remaining = end - start;
        var samplesPerFrame = 1152;


        for (var i = start; remaining >= samplesPerFrame; i += samplesPerFrame) {

            if (!right) {
                var mono = left.subarray(i, i + samplesPerFrame);
                var mp3buf = mp3enc.encodeBuffer(mono);
            }
            else {
                var leftChunk = left.subarray(i, i + samplesPerFrame);
                var rightChunk = right.subarray(i, i + samplesPerFrame);
                var mp3buf = mp3enc.encodeBuffer(leftChunk, rightChunk);
            }
            if (mp3buf.length > 0) {
                buffer.push(mp3buf);
            }
            remaining -= samplesPerFrame;
        }
        var d = mp3enc.flush();
        if (d.length > 0) {
            buffer.push(new Int8Array(d));
        }

        var mp3Blob = new Blob(buffer, { type: 'audio/mpeg' });

        if (toDownload) {
            saveAs(mp3Blob, `${props.fileName.replace("mp3","").replaceAll(".","")}.mp3`);
        } else {
            props.postAudio(mp3Blob);
        }
    }


    const setUpWaveForm = async () => {
        const WaveSurfer = (await import("wavesurfer.js")).default;
       //@ts-ignore
        const RegionsPlugin = (await import("wavesurfer.js/dist/plugin/wavesurfer.regions.min.js"))
            .default;

        let wave = WaveSurfer.create({
            container: '#waveform',
            waveColor: 'gray',
            progressColor: 'black',
            cursorColor: 'black',
            cursorWidth: 2,
            height: 100,
            responsive: false,
            normalize: true,
            partialRender: true,
            plugins: [RegionsPlugin.create()],
        });

        setWaveSurfer(wave);

        wave.load(props.audioFile);

        wave.on('ready', () => {
            setIsReady(true);
            const duration = wave.getDuration();
            setEndTime(duration);
            props.handleStartEndTime(0, duration);
            props.setDuration(duration);
            const region = wave.addRegion({
                start: 0,
                end: duration,
                color: 'rgba(255, 0, 0, 0.3)',
                drag: true,
                resize: true,
            });

            region.on('update-end', async () => {
                setStartTime(region.start);
                setEndTime(region.end);
                props.handleStartEndTime(region.start, region.end);
            });
        });

    }

    useEffect(() => {
        setTimeout(() => {
        setUpWaveForm();
        }, 1000);
    }, [props.audioFile]);

    return (
        <>
            <div id="waveform" />
            {isReady && <> <Box style={{ ...webStyle.saveButton, marginTop: 10 }} onClick={() => downloadMp3(startTime, endTime, true)}>
                <Typography style={webStyle.whiteTextColor}>Save to device</Typography>
            </Box>
                <Box style={{ ...webStyle.saveButton, }} onClick={() => downloadMp3(startTime, endTime, false)} >
                    <Typography style={webStyle.whiteTextColor}>Save to server</Typography>
                </Box> </>}
        </>
    );
}

const webStyle = {
    saveButton: {
        padding: 12,
        backgroundColor: 'teal',
        marginBottom: 10,
    },
    whiteTextColor: {
        color: '#ffffff'
    },
};
