Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validateApiContentType = "multipart/form-data";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AudioEditor";
exports.labelBodyText = "AudioEditor Body";
exports.getAudioDataEndpoint = "bx_block_audiolibrary/audio_categories";
exports.btnExampleTitle = "CLICK ME";
exports.pickAudioFromDeviceLabel = "Pick the audio from device";
exports.supportMp3Label = "Supports only MP3";
exports.pickAudioLabel = "Pick Audio From Device";
exports.pickAudioApiLabel = "Pick Audio From Api";
exports.nameLabel = "Name: ";
exports.sizeLabel = "Size: ";
exports.typeLabel = "Type: ";
exports.startTrimButtonLabel = "Start Trim";
exports.playLabel = "play";
exports.pauseLabel = "pause";
exports.stopLabel = "stop";
exports.totalDurationLabel = "Total Duration: ";
exports.dashLabel = "-";
exports.secondsLabel = "Seconds";
exports.instructionLabel = "1 Second = 1000";
exports.leftPosPlaceholderLabel = "Type here to change left position of trimmer";
exports.rightPosPlaceholderLabel = "Type here to change right position of trimmer";
exports.setLeftPosLabel = "SET L-POS";
exports.setRightPosLabel = "SET R-POS";
exports.zoomMultiplierValue = 20;
exports.initialZoomValue = 2;
exports.maxZoomValue = 200;
exports.saveToDeviceLabel = "Save to device";
exports.isTrimmingLabel = "Trimming please wait...";
exports.trimmedAudioLabel = "Trimmed Audio";
exports.resetLabel = "reset";
exports.pickAudioTestId = "pickAudioTestID";
exports.startTrimBtnTestId = "StartTrimBtnTestID";
exports.startDownloadTrimBtnTestId = "StartDownloadTrimBtnTestID";
exports.trimByStartAndEndTimeTestId = "trimByStartAndEndTimeTestID";
exports.trimByStartAndEndTimeTestId2 = "trimByStartAndEndTimeTestID2";
exports.getAudioTestId = "getAudioTestID";
exports.orLabel = "or";
exports.titleNaLabel = "Title not available";
exports.dropdownLabel = "List of audio from api";
exports.selectedItemColor = "rgba(0, 0, 0, .87)";
exports.urlLabel = "URL: ";
exports.saveToApiLabel = "Save to server";
exports.resetButtonTestId = "resetButtonTestID";
exports.postAudioEndpoint = "bx_block_audioeditor/trimed_audios";
exports.downloadingLabel = "Downloading please wait ...";
exports.playButtonTestId = "playButtonTestID";
exports.pauseButtonTestId = "pauseButtonTestID";
exports.stopButtonTestId = "stopButtonTestID";
exports.drowdownTestId = "drowdownTestID";
exports.lPosTextInputTextId = "LPOSTextInputTextID";
exports.rPosTextInputTextId = "RPOSTextInputTextID";
exports.lPosSetButtonTestId = "LPOSSetButtonTestID";
exports.rPosSetButtonTestId = "RPOSSetButtonTestID";
exports.resetButtonTestId2 = "resetButtonTestID2";
exports.audioBlobType = "audio/mp3";
exports.audioBlobName = "trimmedaudio.mp3";
exports.formDataAppendKey1 = "[data][trim_audio]";
exports.formDataAppendKey2 = "[data][start_time]";
exports.formDataAppendKey3 = "[data][end_time]";
exports.formDataAppendKey4 = "[data][audio_id]";
exports.formDataAppendValue2 = "00:01:00";
exports.formDataAppendValue3 = "00:01:30";
exports.formDataAppendValue4 = "48";
exports.infoCommandFfmpeg = "-i";
exports.secondsCommandFfmpeg = "-ss";
exports.timeCommandFfmpeg = "-t";
// Customizable Area End
