import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  Modal,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LiveFeedSchedulingController, { Props } from "./LiveFeedSchedulingController";

export default class LiveFeedScheduling extends LiveFeedSchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSchedules = () => {
    return this.state.schedules.map((schedule, index) => {
      return (
        <div
          key={index + "schedule"}
          style={{
            display: "flex",
            border: "solid",
            padding: "5px",
            borderRadius: "15px",
            margin: "5px",
          }}
        >
          <div style={{ width: "300px", alignSelf: "center" }}>{schedule.event_name}</div>
          <Button
            data-test-id={"editButton"}
            color={"secondary"}
            onClick={() => this.openModal(schedule)}
          >
            Edit
          </Button>
          <Button
            data-test-id={"deleteButton"}
            color={"secondary"}
            onClick={() => this.deleteSchedule(schedule)}
          >
            Del
          </Button>
        </div>
      );
    });
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant='h6'>{"Scheduled Events:"}</Typography>
            <div>{this.renderSchedules()}</div>
          </Box>
          <div style={{ textAlign: "center" }}>
            <Button
              data-test-id={"createButton"}
              style={webStyle.buttonStyle}
              color={"secondary"}
              onClick={() => this.openModal()}
            >
              Schedule New Event
            </Button>
          </div>
        </Container>
        <Modal
          open={this.state.modalVisible}
          onClose={this.onModalClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={webStyle.modalStyle}>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id='service-shop-name'>Event Topic</InputLabel>
              <Input
                data-test-id={"event_name"}
                type={"text"}
                placeholder={"Event Topic"}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.currentSchedule.event_name}
                onChange={(event) => this.setEventName(event.target.value)}
              />
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id='service-shop-name'>Event Description</InputLabel>
              <Input
                data-test-id={"description"}
                type={"text"}
                placeholder={"Description"}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.currentSchedule.description}
                multiline={true}
                onChange={(event) => this.setDescription(event.target.value)}
              />
            </Box>
            <Box sx={webStyle.dateStyle}>
              <InputLabel id='service-shop-name'>Select Date</InputLabel>
              <DatePicker
                data-test-id={"date"}
                selected={
                  new Date(this.state.currentSchedule.start_time).toString() !== "Invalid Date"
                    ? new Date(this.state.currentSchedule.start_time)
                    : null
                }
                onChange={(date: Date) => this.setStartTime(date)}
              />
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id='service-shop-name'>Select Time</InputLabel>
              <div style={{ display: "flex" }}>
                <Input
                  data-test-id={"hour"}
                  type={"text"}
                  placeholder={"00"}
                  disableUnderline={true}
                  value={this.state.currentSchedule.start_hour
                    .split(" ")[0]
                    .substring(0, this.state.currentSchedule.start_hour.indexOf(":"))}
                  onChange={(event) => this.setStartHour(event.target.value)}
                  onBlur={(event) => this.setStartHour(event.target.value.padStart(2, "0"))}
                  style={{ width: "30px", borderBottom: "1px solid rgba(0, 0, 0, 0.6)" }}
                  onClick={(event) => (event.target as HTMLInputElement).select()}
                />
                <div style={{ marginTop: "5px", marginRight: "5px" }}>:</div>
                <Input
                  data-test-id={"minute"}
                  type={"text"}
                  placeholder={"00"}
                  disableUnderline={true}
                  value={this.state.currentSchedule.start_hour
                    .split(" ")[0]
                    .substring(this.state.currentSchedule.start_hour.indexOf(":") + 1)}
                  onChange={(event) => this.setStartMinute(event.target.value)}
                  onBlur={(event) => this.setStartMinute(event.target.value.padStart(2, "0"))}
                  style={{ width: "30px", borderBottom: "1px solid rgba(0, 0, 0, 0.6)" }}
                  onClick={(event) => {
                    (event.target as HTMLInputElement).select();
                  }}
                />
                <div style={{ marginTop: "5px", marginLeft: "5px" }} />
                <div style={{ marginTop: "5px", marginLeft: "5px", alignSelf: "center" }}>AM</div>
                <Checkbox
                  data-test-id={"AM"}
                  checked={this.state.currentSchedule.start_hour.indexOf("AM") >= 0}
                  onChange={() => this.setStartAmPm("AM")}
                />
                <div style={{ marginTop: "5px", marginLeft: "5px", alignSelf: "center" }}>PM</div>
                <Checkbox
                  data-test-id={"PM"}
                  checked={this.state.currentSchedule.start_hour.indexOf("PM") >= 0}
                  onChange={() => this.setStartAmPm("PM")}
                />
              </div>
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id='service-shop-name'>Duration</InputLabel>
              <div style={{ display: "flex" }}>
                <Input
                  data-test-id={"durHour"}
                  type={"text"}
                  placeholder={"00"}
                  disableUnderline={true}
                  value={this.state.currentSchedule.duration.substring(
                    0,
                    this.state.currentSchedule.duration.indexOf(":")
                  )}
                  onChange={(event) => this.setDurationHour(event.target.value)}
                  onBlur={(event) => this.setDurationHour(event.target.value.padStart(2, "0"))}
                  style={{ width: "30px", borderBottom: "1px solid rgba(0, 0, 0, 0.6)" }}
                  onClick={(event) => (event.target as HTMLInputElement).select()}
                />
                <div style={{ marginTop: "5px", marginRight: "5px" }}>:</div>
                <Input
                  data-test-id={"durMinute"}
                  type={"text"}
                  placeholder={"00"}
                  disableUnderline={true}
                  value={this.state.currentSchedule.duration.substring(
                    this.state.currentSchedule.duration.indexOf(":") + 1
                  )}
                  onChange={(event) => this.setDurationMinute(event.target.value)}
                  onBlur={(event) => this.setDurationMinute(event.target.value.padStart(2, "0"))}
                  style={{ width: "30px", borderBottom: "1px solid rgba(0, 0, 0, 0.6)" }}
                  onClick={(event) => (event.target as HTMLInputElement).select()}
                />
              </div>
            </Box>
            <Box>
              <Button
                data-test-id={"saveButton"}
                style={webStyle.buttonStyle}
                color={"secondary"}
                onClick={() => this.editCreateSchedule(!this.state.isEdit)}
              >
                Schedule Live
              </Button>
            </Box>
          </Box>
        </Modal>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    width: "100%",
    height: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "40px",
  },
  dateStyle: {
    width: "100%",
    height: "60px",
    marginTop: "40px",
  },
  buttonStyle: {
    width: "180px",
    height: "45px",
    marginTop: "20px",
    backgroundColor: "yellow",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    height: 600,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  },
};
// Customizable Area End
