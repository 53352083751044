Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.listEventAPiEndPoint = "bx_block_live_feed_schedule/list_events";
exports.eventAPiEndPoint = "bx_block_live_feed_schedule/event";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LiveFeedScheduling";
exports.labelBodyText = "LiveFeedScheduling Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End